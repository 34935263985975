import React from "react"
import { graphql } from "gatsby";

// import Layout from "../components/layout"
const PageTemplate = ({data}) => {

	console.log('templatefdata',data)
	return (
		
		    <h1>AA {data && data.teamMembers && data.teamMembers.name}</h1>
		
	)
}

export const query = graphql`
    query($name: String) {
        teamMembers(name: { eq: $name }) {
            name
            id
            id_normalized
        }
    }
`;


export default PageTemplate